import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { omit, pickBy } from "lodash";

import { Box } from "@material-ui/core";

import {
  HSALimit,
  HSALimitMarried,
  ROTH_IRA_MAGI_MARRIED_MAX,
  ROTH_IRA_MAGI_MAX,
} from "src/constants";
import {
  getAccounts,
  getMyFedLoanPayments,
  getSpouseFedLoanPayments,
} from "src/store/account/selector";
import { setRefreshNeeded } from "src/store/dashboard/actions";
import {
  estimateCurrentPlanTaxes,
  savePlan,
  setBuildStep,
  setPlanDirty,
  updateAllocations,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import { PLAN_BUILD_STEPS } from "src/store/planBuild/constants";
import {
  currentPlanAllocationTotals,
  currentPlanIncomeTotal,
  getCurrentPlan,
  getFormattedAllocations,
  getMy401kEligibleIncome,
  getSpouse401kEligibleIncome,
  getLiabilities,
  getMyIRAContributions,
  getMyMax401kDollarContribution,
  getMyMaxRoth401kDollarContribution,
  getPlanIsDirty,
  getSpouseIRAContributions,
  getSpouseMax401kDollarContribution,
  getSpouseMaxRoth401kDollarContribution,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import { SPECIAL_ALLOCATIONS } from "src/interfaces/plan.interface";
import { PlanViewComponent } from "src/interfaces/viewComponent.interface";
import { formatAnnually, formatMonthly, formatPercent } from "src/utils";
import AddAssetOrDebt from "./AddAssetOrDebt";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import BasicCard from "src/components/BasicCard";
import {
  DEBT_TYPES,
  ShortTermGoals,
  TAX_DEFERRED_INVESTMENT_TYPES,
} from "src/interfaces";
import CenterContainer from "../../Components/CenterContainer";

const MainForm: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const plan = useSelector(getCurrentPlan);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const { formattedAssets, formattedDebts }: any = useSelector(
    getFormattedAllocations
  );
  const currentPlanTotals = useSelector(currentPlanAllocationTotals);
  const earnedIncome = useSelector(getMy401kEligibleIncome);
  const earnedIncomeSpouse = useSelector(getSpouse401kEligibleIncome);
  const accounts = useSelector(getAccounts);
  const liabilities = useSelector(getLiabilities);
  const dirty = useSelector(getPlanIsDirty);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const myFedLoanPayments = useSelector(getMyFedLoanPayments);
  const spouseFedLoanPayments = useSelector(getSpouseFedLoanPayments);
  const myIRAContributions = useSelector(getMyIRAContributions);
  const spouseIRAContributions = useSelector(getSpouseIRAContributions);
  const myMax401kDollarContribution = useSelector(
    getMyMax401kDollarContribution
  );
  const myMaxRoth401kDollarContribution = useSelector(
    getMyMaxRoth401kDollarContribution
  );
  const spouseMax401kDollarContribution = useSelector(
    getSpouseMax401kDollarContribution
  );
  const spouseMaxRoth401kDollarContribution = useSelector(
    getSpouseMaxRoth401kDollarContribution
  );
  const [addingItem, setAddingItem] = useState(false);
  const [isDebt, setIsDebt] = useState(false);
  const [editingSpecialType, setEditingSpecialType] = useState<any>(null);
  const shortTermGoalsPresent = plan.lifeevents.filter(
    (lifeEvent) =>
      ShortTermGoals[lifeEvent.eventtype as keyof typeof ShortTermGoals]
  ).length;
  const curatedStartingPoint = shortTermGoalsPresent
    ? CURATED_PLAN_BUILD_STEPS.SHORT_TERM_GOAL
    : CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT;
  const [curatedStepIndex, setCuratedStepIndex] = useState(
    curatedStartingPoint
  );
  const priority = plan.profile.priority;
  const debtCategoriesToDisplay = new Set<string>([]);
  const debtAccountsWithBalances = accounts.filter((account) => {
    const isDebtType = DEBT_TYPES[account.variable as any];
    if (isDebtType && !!account.balance) {
      debtCategoriesToDisplay.add(account.variable as string);
      return true;
    }
    return false;
  });
  const myStudentLoanLiability = currentPlanIsImplemented
    ? myFedLoanPayments * 12
    : liabilities.min.solo[0].fed_loan || 0;
  const spouseStudentLoanLiability = currentPlanIsImplemented
    ? spouseFedLoanPayments * 12
    : liabilities.min.solo[1].fed_loan || 0;
  const displayDebts = (currentPlanIsImplemented
    ? formattedDebts.filter((item: any) =>
        debtCategoriesToDisplay.has(item.type)
      )
    : formattedDebts
  ).map((item: any) => {
    let result = { ...item };
    if (item.type === "fed_loan") {
      const annualLiability =
        item.who === "spouse"
          ? spouseStudentLoanLiability
          : myStudentLoanLiability;
      result = {
        ...item,
        min_annual: annualLiability,
        min_monthly: annualLiability / 12,
        min: annualLiability / totalIncome,
      };
    }
    if (currentPlanIsImplemented) {
      result.annual = Math.max(result.annual, result.min_annual);
      result.monthly = Math.max(result.monthly, result.min_monthly);
      result.percent = Math.max(result.percent, result.min);
    }
    return result;
  });
  const displayAssets = formattedAssets.map((asset: any) => {
    let limit: any = null;
    let contributions: any = null;
    switch (asset.type) {
      case "ira_value":
        contributions =
          asset.who === "spouse" ? spouseIRAContributions : myIRAContributions;
        limit = contributions.maxDollars - contributions.values[1];
        break;
      case "roth_ira_value":
        contributions =
          asset.who === "spouse" ? spouseIRAContributions : myIRAContributions;
        limit = contributions.maxDollars - contributions.values[0];
        break;
      case "hsa_value":
        limit = isMarried ? HSALimitMarried : HSALimit;
        break;
      case "401k_value":
        limit =
          asset.who === "spouse"
            ? spouseMax401kDollarContribution
            : myMax401kDollarContribution;
        break;
      case "roth_401k_value":
        limit =
          asset.who === "spouse"
            ? spouseMaxRoth401kDollarContribution
            : myMaxRoth401kDollarContribution;
        break;
      default:
        break;
    }
    if (limit !== null) {
      return {
        ...asset,
        max_annual: limit,
        max_monthly: limit / 12,
        max: (limit / totalIncome) * 100,
      };
    }
    return asset;
  });

  const hasDebtBalance = !!debtAccountsWithBalances.length;

  const { assetsTotal, debtsTotal } = currentPlanTotals;

  const onNext = () => {
    if (dirty) {
      dispatch(savePlan(PLAN_BUILD_STEPS.RISK_MANAGEMENT));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.RISK_MANAGEMENT));
    }
  };
  const addDebt = () => {
    setIsDebt(true);
    setAddingItem(true);
  };
  const addAsset = () => {
    setIsDebt(false);
    setAddingItem(true);
  };

  const openForEditing = (item: any) => {
    setEditingSpecialType(item);
  };

  const hasBalance = (accountType: string) => {
    const accountBalances = accounts.reduce((result: number, entry: any) => {
      if (entry.variable === accountType && entry.balance) {
        return result + entry.balance;
      }
      return result;
    }, 0);
    return !!accountBalances;
  };

  const remove401kAllocation = (soloIndex: number) => {
    const eligibleIncome = soloIndex ? earnedIncomeSpouse : earnedIncome;
    const soloAllocations = plan.allocations[0].solo;
    const newSoloAllocations = [...soloAllocations];
    const updateSoloAllocation = { ...newSoloAllocations[soloIndex] };
    const dollarContribution =
      ((updateSoloAllocation["401k_value"] || 0) / 100) * eligibleIncome;
    const totalContribution = (dollarContribution / totalIncome || 0) * 100;
    updateSoloAllocation["401k_value"] = 0;
    newSoloAllocations[soloIndex] = updateSoloAllocation;
    let newAllocations = {
      ...plan.allocations[0],
      solo: newSoloAllocations,
    };
    const new401kValue =
      (plan.allocations[0]["401k_value"] || 0) - totalContribution;
    if (new401kValue <= 0.01) {
      newAllocations = omit(newAllocations, "401k_value");
    } else {
      newAllocations["401k_value"] = new401kValue;
    }
    return newAllocations;
    if (currentPlanIsImplemented) {
      dispatch(setRefreshNeeded({ projection: true, summaries: true }));
    }
  };

  const removeItem = (item: any) => {
    let newAllocations = { ...plan.allocations[0] };
    if (SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0) {
      const soloIndex = item.who === "spouse" ? 1 : 0;
      if (item.type === "401k_value") {
        newAllocations = remove401kAllocation(soloIndex);
      } else {
        if (item.type === "hsa_value") {
          dispatch(
            updateCurrentPlan({ profile: { ...plan.profile, has_hsa: false } })
          );
        }
        const otherIndex = soloIndex ? 0 : 1;
        const soloAllocations = plan.allocations[0].solo;
        const newSoloAllocations = [...soloAllocations];
        newSoloAllocations[soloIndex] = {
          ...newSoloAllocations[soloIndex],
          [item.type]: 0,
        };
        newAllocations = { ...newAllocations, solo: newSoloAllocations };
        if (isMarried) {
          const otherSoloAllocation: any = newSoloAllocations[otherIndex];
          if (!otherSoloAllocation || !otherSoloAllocation[item.type]) {
            newAllocations = {
              ...pickBy(
                newAllocations,
                (value: any, key: string) => key !== item.type
              ),
              solo: newSoloAllocations,
            };
          } else {
            newAllocations = {
              ...newAllocations,
              solo: newSoloAllocations,
              [item.type]: otherSoloAllocation[item.type],
            };
          }
        } else {
          newAllocations = {
            ...pickBy(
              plan.allocations[0],
              (value: any, key: string) => key !== item.type
            ),
            solo: newAllocations.solo,
          };
        }
      }
    } else {
      newAllocations = {
        ...pickBy(
          plan.allocations[0],
          (value: any, key: string) => key !== item.type
        ),
        solo: newAllocations.solo,
      };
    }
    dispatch(updateAllocations(newAllocations));
    if (currentPlanIsImplemented) {
      dispatch(setRefreshNeeded({ projection: true, summaries: true }));
    }
    if (item.type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
  };

  const saveItem = (item: any, newValues: any) => {
    const newAllocations = {
      ...plan.allocations[0],
      [item.type]: newValues.annual,
    };
    if (
      item.type === "hsa_value" &&
      !!newValues.annual &&
      !plan.profile.has_hsa
    ) {
      dispatch(
        updateCurrentPlan({ profile: { ...plan.profile, has_hsa: true } })
      );
    }
    dispatch(updateAllocations(newAllocations));
    if (currentPlanIsImplemented) {
      dispatch(setRefreshNeeded({ projection: true, summaries: true }));
    }
    if (item.type in TAX_DEFERRED_INVESTMENT_TYPES) {
      dispatch(estimateCurrentPlanTaxes());
    }
    dispatch(savePlan(null));
  };

  const closeAddEdit = () => {
    setAddingItem(false);
    setEditingSpecialType(null);
  };

  const getUserApprovedForRoth = (who: "applicant" | "spouse") => {
    let eligibleIncome;
    let max;
    if (isMarried) {
      eligibleIncome = earnedIncome + earnedIncomeSpouse;
      max = ROTH_IRA_MAGI_MARRIED_MAX;
    } else {
      eligibleIncome = who === "spouse" ? earnedIncomeSpouse : earnedIncome;
      max = ROTH_IRA_MAGI_MAX;
    }
    return eligibleIncome && eligibleIncome < max;
  };

  const handleCuratedFlow = () => {
    // if we are in the curated plan build flow
    if (
      curatedStepIndex &&
      curatedStepIndex <= CURATED_PLAN_BUILD_STEPS.OTHER_DEBT
    ) {
      let nextStep = curatedStepIndex + 1;
      // skip asking the roth_ira question if user doesn't make enough
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT &&
        !earnedIncome
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.EMPLOYER_RETIREMENT_SPOUSE &&
        (!isMarried || !earnedIncomeSpouse)
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.ROTH_IRA &&
        !getUserApprovedForRoth("applicant")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.ROTH_IRA_SPOUSE &&
        (!isMarried || !getUserApprovedForRoth("spouse"))
      ) {
        nextStep += 1;
      }
      // if there are no debt balances drop the asset debt question
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.INVEST_OR_PAYOFF &&
        !hasDebtBalance
      ) {
        nextStep += 1;
      }
      // end the flow before the debt questions if the priority is to invest OR if user has not debt
      if (
        nextStep > CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN &&
        (priority === "asset" || !hasDebtBalance)
      ) {
        nextStep = 0;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN &&
        !liabilities.federal.applicant
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.STUDENT_LOAN_SPOUSE &&
        (!liabilities.federal.spouse || !isMarried)
      ) {
        nextStep += 1;
      }
      if (nextStep === CURATED_PLAN_BUILD_STEPS.HIGH_LOW && !hasDebtBalance) {
        nextStep = 0;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.PERKINS_LOANS &&
        !hasBalance("perkins_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.PRIVATE_LOANS &&
        !hasBalance("priv_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.CREDIT_CARD_LOANS &&
        !hasBalance("credit_card")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.PERSONAL_LOANS &&
        !hasBalance("personal_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.PRIMARY_MORTGAGE &&
        !hasBalance("home_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.INVESTMENT_PROPERTY_MORTGAGE &&
        !hasBalance("property_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.AUTO_DEBT &&
        !hasBalance("auto_loan")
      ) {
        nextStep += 1;
      }
      if (
        nextStep === CURATED_PLAN_BUILD_STEPS.OTHER_DEBT &&
        !hasBalance("other_debt")
      ) {
        nextStep += 1;
      }
      if (nextStep > CURATED_PLAN_BUILD_STEPS.OTHER_DEBT) {
        nextStep = 0;
      }

      setCuratedStepIndex(nextStep);
    } else {
      // dispatch(savePlan(PLAN_BUILD_STEPS.RISK_MANAGEMENT));
      closeAddEdit();
    }
  };

  //                                        if we are in the curated plan build flow and we are not done with it
  if (
    addingItem ||
    editingSpecialType ||
    (curatedStepIndex &&
      curatedStepIndex <= CURATED_PLAN_BUILD_STEPS.OTHER_DEBT)
  ) {
    return (
      <AddAssetOrDebt
        editingSpecialType={editingSpecialType}
        isDebt={isDebt}
        // onClose={closeAddEdit}
        onClose={handleCuratedFlow}
        render={render}
        curatedIndex={curatedStepIndex}
      />
    );
  }

  const assetColumns = [
    { label: "Type", field: "type", type: "fixed", width: "47%" },
    {
      label: "% of Total Income",
      field: "percent",
      type: "percent",
      width: "20%",
      formatter: formatPercent,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "18%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "18%",
      formatter: formatAnnually,
    },
  ];

  const debtColumns = [
    { label: "Type", field: "type", type: "fixed", width: "47%" },
    {
      label: "% of Total Income",
      field: "percent",
      type: "percent",
      width: "20%",
      formatter: formatPercent,
    },
    {
      label: "Monthly",
      field: "monthly",
      type: "number",
      width: "18%",
      formatter: formatMonthly,
    },
    {
      label: "Annual",
      field: "annual",
      type: "number",
      width: "18%",
      formatter: formatAnnually,
    },
  ];

  if (isMarried) {
    assetColumns[0].width = "20%";
    assetColumns.push({
      label: "Owner",
      field: "who",
      type: "fixed",
      width: "17%",
    });
    debtColumns[0].width = "20%";
    debtColumns.push({
      label: "Owner",
      field: "who",
      type: "fixed",
      width: "17%",
    });
  }

  return render({
    component: (
      <CenterContainer
        scrollable
        title="Money For Future Goals"
        iconName="fb-model"
      >
        <Box className="mt-5">
          <BasicCard
            iconName="fb-model"
            addLabel="Add Asset Contribution"
            title="Assets"
            columns={assetColumns}
            data={displayAssets}
            // plan={plan}
            onAdd={addAsset}
            onDelete={removeItem}
            specialEdit={{
              check: (item: any) => SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0,
              handle: openForEditing,
            }}
            onSave={saveItem}
            total={totalIncome}
            summaryRow={
              !formattedAssets.length
                ? undefined
                : {
                    type: "Total Financial Assets",
                    annual: assetsTotal,
                    monthly: Math.round(assetsTotal / 12),
                    percent: (assetsTotal / totalIncome) * 100,
                  }
            }
            setDirtyFlag={() => dispatch(setPlanDirty(true))}
          />
          <BasicCard
            iconName="fb-scales-tripped"
            title="Debts"
            addLabel="Add Debt Payments"
            columns={debtColumns}
            data={displayDebts}
            onAdd={addDebt}
            total={totalIncome}
            onDelete={removeItem}
            specialEdit={{
              check: (item: any) => SPECIAL_ALLOCATIONS.indexOf(item.type) >= 0,
              handle: openForEditing,
            }}
            onSave={saveItem}
            summaryRow={
              !formattedDebts.length
                ? undefined
                : {
                    type: "Total Debts",
                    annual: debtsTotal,
                    monthly: Math.round(debtsTotal / 12),
                    percent: (debtsTotal / totalIncome) * 100,
                  }
            }
            setDirtyFlag={() => dispatch(setPlanDirty(true))}
          />
        </Box>
      </CenterContainer>
    ),
    nextLabel: "Next Section",
    onNext,
  });
};

export default MainForm;
