import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { useStyles } from "./styles";
import Button from "src/components/Button";
import { PlanViewComponent, SidebarContent } from "src/interfaces";
import {
  savePlan,
  updateAllocations,
  estimateCurrentPlanTaxes,
  updateCurrentPlan,
} from "src/store/planBuild/actions";
import {
  currentPlanIncomeTotal,
  getCurrentPlan,
} from "src/store/planBuild/selector";
import { getIsMarried } from "src/store/system/selector";

import CenterContainer from "../../Components/CenterContainer";
import FbRadio from "src/components/Radio";
import { CURATED_PLAN_BUILD_STEPS } from "./common";
import { HSALimit, HSALimitMarried } from "src/constants";
import PercentBreakdown from "src/components/PercentBreakdown";

export const ShortTermGoals = {
  marriage: "Save for a wedding",
  house: "Save to purchase a house",
  vehicle: "Save to purchase an investment",
  property: "Save to purchase a vehicle",
};

const messages = {
  single:
    "Does your employer offer a Health Savings Account (HSA)? If so, will you be contributing to it?",
  married:
    "Do you or your spouse's employer offer a Health Savings Account (HSA)? If so, will you be contributing to it?",
};

const Hsa: PlanViewComponent = ({ onClose, onSave, render, curatedIndex }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const isMarried = useSelector(getIsMarried);
  const totalIncome = useSelector(currentPlanIncomeTotal);
  const plan = useSelector(getCurrentPlan);
  const [formState, setFormState] = useState<any>({
    has_hsa: plan.profile.has_hsa ? "y" : "n",
    percent: "",
    contribution: 0,
  });
  const promptMessage = isMarried ? messages.married : messages.single;

  const maxDollars = isMarried ? HSALimitMarried : HSALimit;

  const updateField = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value = e.target.value;
    setFormState({ ...formState, [field]: value });
  };

  const save = () => {
    if (formState.has_hsa === "y") {
      dispatch(
        updateCurrentPlan({
          profile: { ...plan.profile, has_hsa: formState.has_hsa === "y" },
        })
      );
    }
    const newAllocations = {
      ...plan.allocations[0],
      hsa_value: formState.has_hsa === "y" ? formState.contribution : 0,
    };
    dispatch(updateAllocations(newAllocations));
    // HSA is tax deferred contributions, estimate the new taxes
    dispatch(estimateCurrentPlanTaxes());
    dispatch(savePlan(null));
    onSave();
  };

  const nextDisabled = formState.contribution > maxDollars;

  return render({
    component: (
      <CenterContainer
        title={"Health Savings Account"}
        iconName={"fb-model"}
        help={help}
        scrollable
      >
        <Box className="my-5">
          {curatedIndex && curatedIndex === CURATED_PLAN_BUILD_STEPS.HSA && (
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" className="mb-4">
                  <Typography style={{ fontSize: "13px" }}>
                    {promptMessage}
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-label="employer_offer"
                  name="has_hsa"
                  onChange={updateField}
                  value={formState.has_hsa}
                  className="flex flex-row"
                >
                  <FormControlLabel
                    value="n"
                    control={<FbRadio />}
                    label="No"
                  />
                  <FormControlLabel
                    value="y"
                    control={<FbRadio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Box>

        {formState.has_hsa === "y" && (
          <Box className="my-6">
            <PercentBreakdown
              // min={min}
              max={maxDollars}
              income={totalIncome}
              onChange={setFormState}
              values={formState}
              label="HSA"
            />
          </Box>
        )}
        {!curatedIndex && (
          <Button
            variant="outlined"
            fbColor="primary"
            onClick={onClose}
            className={styles.btnCancel}
          >
            Cancel
          </Button>
        )}
      </CenterContainer>
    ),
    nextDisabled,
    nextTooltip: nextDisabled
      ? "You must enter an amount that does not exceed the maximum contribution."
      : undefined,
    nextLabel: "Save",
    onNext: save,
  });
};

export default Hsa;

const help: SidebarContent[] = [
  {
    header: "Recommendation For HSA",
    body: [
      "If you choose a high deductible health insurance plan through your employer and they offer an HSA, we highly recommend contributing at least $50 per month to the HSA.  Doing so will help with retirement income diversification.",
      "Retirement income diversification is when you hold your assets in as many different types of accounts as you can.  Therefore, at retirement, if the government changes how they tax one type of an account, you have others that won't be affected.",
    ],
  },
  {
    header: "What is a Health Savings Account?",
    body: [
      "Health Savings Accounts (HSA for short) are tax advantaged accounts with special features for withdraws related to health care costs. Below is a summary of features:",
      "1) Contributions you make each year reduce how much you pay in taxes in the year the contribution was made.",
      "2) You invest the money and you don't pay taxes each year on investment gains.",
      "3) If you have a medical expense, you can take the money out anytime you want and not pay penalties or taxes on it.",
      "4) After age 65, you can withdraw the money for any reason without penalty. However, if you take the money out for non-medical reasons, you'll owe income tax on it.",
    ],
  },
];
