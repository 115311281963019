import React, { useMemo } from "react";
import { EuiBasicTable, EuiButton, EuiBasicTableColumn, EuiTableActionsColumnType, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { formatWholeDollars } from "src/utils";
import { ALL_GRADUATED_LIFE_EVENTS } from "src/interfaces";
import { StyledSpacer } from "src/components/Global/StyledComponents";

interface LifeEvent {
    eventtype: string;
    date: string;
    down?: number;
    amount?: number;
    who?: string;
    typeLabel?: string;
    dateLabel?: string;
    savingsLabel?: string;
    rawIndex: number;
}

interface Profile {
    dob_year: number;
}

interface LifeEventsTableProps {
    sortedLifeEvents: LifeEvent[];
    openEdit: (event: LifeEvent, index: number) => void;
    removeItem: (index: number) => void;
    setShowCategories: (show: boolean) => void;
    isMarried: boolean;
    spouseProfile: Profile;
    myProfile: Profile;
    spouseInSchool: boolean;
    userInSchool: boolean;
}

const LifeEventsTable: React.FC<LifeEventsTableProps> = ({
    sortedLifeEvents,
    openEdit,
    removeItem,
    setShowCategories,
    isMarried,
    spouseProfile,
    myProfile,
    spouseInSchool,
    userInSchool,
}) => {
    const columns: Array<EuiBasicTableColumn<LifeEvent> | EuiTableActionsColumnType<LifeEvent>> = [
        {
            field: "typeLabel",
            name: "Life Event/Goal",
        },
        {
            field: "dateLabel",
            name: "Target Date",
        },
        {
            field: "savingsLabel",
            name: "Target Amount",
        },
        {
            name: "Actions",
            actions: [
                {
                    type: "button",
                    icon: "pencil" as const,
                    color: "primary",
                    onClick: (event: LifeEvent) => openEdit(event, event.rawIndex),
                    description: "Edit event",
                },
                {
                    type: "button",
                    color: "danger",
                    onClick: (event: LifeEvent) => removeItem(event.rawIndex),
                    description: "Delete event",
                },
            ] as EuiTableActionsColumnType<LifeEvent>["actions"],
        } as EuiTableActionsColumnType<LifeEvent>,
    ];


    const items = useMemo(() => {
        return sortedLifeEvents.map((event) => {
            const eventDefinition = ALL_GRADUATED_LIFE_EVENTS[event.eventtype];
            if (!eventDefinition) return null;

            let typeLabel = eventDefinition.typeLabel || event.typeLabel || "Event";
            const isRetirementEvent = event.eventtype === "retire";

            if (isRetirementEvent) {
                let dobYear = myProfile.dob_year;
                if (event.who === "spouse") {
                    if (!isMarried) return null;
                    dobYear = spouseProfile.dob_year;
                    typeLabel += " (Spouse)";
                }
                const retirementYear = +event.date.slice(0, 4);
                event.dateLabel = `Age ${retirementYear - dobYear}`;
            } else {
                const month = event.date ? event.date.slice(5, 7) : "N/A";
                const year = event.date ? event.date.slice(0, 4) : "N/A";
                event.dateLabel = `${month}-${year}`;
            }

            event.typeLabel = typeLabel;
            event.savingsLabel =
                event.down || event.amount ? `Savings Goal: ${formatWholeDollars(event.down ?? event.amount ?? 0)}` : "N/A";

            return event;
        }).filter(Boolean) as LifeEvent[];
    }, [sortedLifeEvents, isMarried, spouseProfile, myProfile, spouseInSchool, userInSchool]);

    console.log("LifeEventsTable items:", items);

    return (
        <>
            <EuiFlexGroup direction="column">
                <EuiFlexItem grow={false} style={{ alignSelf: 'flex-end' }}>
                    <StyledSpacer size="24px" />
                    <EuiButton onClick={() => setShowCategories(true)} iconType="plusInCircle" color="text" style={{ border: '1px solid'}}>
                        Add Life Event/Goal
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiBasicTable items={items} columns={columns} className="table-border" />
                </EuiFlexItem>
                <StyledSpacer size="24px" />
            </EuiFlexGroup>
        </>
    );
};

export default LifeEventsTable;